import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import { Heading } from "../components/styles/Sections"
import styled from "styled-components"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BreadCrumbs from "../components/breadcrumbs"
import { Button } from "../components/styles/Buttons"
import { SectionContainerGridThreeCols, SectionWrapper, SectionBlue, SectionTitleContainer, BottomDivider } from "../components/styles/Sections"
import ArticleComponent from "../components/ArticleComponent"
import Footer from "../components/footer"
import Icon from "../components/Icon"
import ShareButtons from "../components/ShareButtons"

const BlogPostTemplate = ({ data: { article, relatedPosts }, location }) => {
  const filteredPosts = relatedPosts.nodes.filter(item => !item.id.includes(article.id))
  const canonicalUrl = article.seo ? "https://svenskaonlinecasinon.se" + article.seo.canonical : undefined;
  const ogImage = article.featuredImage ? article.featuredImage.node.sourceUrl : undefined;
  const schema = article.seo ? article.seo.schema.raw : undefined;
  const seoMetaDescription = article.seo ? article.seo.metaDesc : undefined;
  const seoTitle = article.seo ? article.seo.title : article.title;

  const relatedPostArray = [];

  return (
    <Layout>
      <Seo 
        title={seoTitle}
        description={seoMetaDescription}
        canonical={canonicalUrl} 
        modified={article.modified} 
        ogImage={ogImage}
        ogType={"article"}
        ogUrl={canonicalUrl} 
        schema={schema}
      />  
      <BreadCrumbs align="center" items={article.seo.breadcrumbs} modified={article.modified} />
      <article className="containerSmall" id="main-content">
        <Heading>
          <header>
            <div className="info">
              <div><Icon icon="user1" size={13} /> {article.author.node.name}</div>  
              <div><Icon icon="calendar" size={12} /> {article.date}</div>
              <div><Icon icon="clock" size={12} /> {article.seo.readingTime} minuter</div> 
            </div>
            <h1 itemProp="headline">{parse(article.title)}</h1>
            {article?.categories && (
              <Categories>
                {article.categories.nodes.map((category) => (
                  <span className="category">{category.name}</span>
                ))}
              </Categories>
            )}
          </header>
        </Heading>
        {!!article.content && (
          <Fragment>
            <section>{parse(article.content)}</section>
            <ShareButtons url={canonicalUrl} />
            <Bio author={article.author.node} />
          </Fragment>
        )}
      </article>
      {filteredPosts.map((post) => (
        <Fragment>
          {post.categories.nodes.map((category) => (
            <Fragment>
              {article.categories.nodes.map((item) => {
                if(category.id === item.id) {
                  relatedPostArray.push(post)
                }
              })} 
            </Fragment>                 
          ))}
        </Fragment>
      ))}
      {relatedPostArray.length > 0 &&
        <SectionBlue>
          <SectionWrapper>
            <SectionTitleContainer hasButton>
              <h2>Relaterade nyheter</h2>
              <Link to="/nyheter"><Button className="blue">Visa alla nyheter</Button></Link>
            </SectionTitleContainer>
            <SectionContainerGridThreeCols>
              {relatedPostArray.slice(0, 3).map((item) => (
                <ArticleComponent item={item} hideCategories={true} /> 
              ))}
            </SectionContainerGridThreeCols>
          </SectionWrapper>
          <BottomDivider />
        </SectionBlue>
      }
      <Footer location={location} />
    </Layout>
  )
}

export default BlogPostTemplate

const Categories = styled.div`
  display: flex;
  grid-gap: 10px;
  justify-content: center;

  .category {
    border: 1px solid var(--color-blue);
    padding: 4px 8px;
    border-radius: 8px;
    color: var(--color-blue);
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
  }
`;

export const articleQuery = graphql`
  query ArticleById(
    $id: String!
  ) {
    article: wpNyheter(id: { eq: $id }) {
      id
      excerpt
      content
      title
      slug
      author {
        node {
          name
          description
          uri
          avatar {
            url
          }
          authorSocials {
            linkedin
          }
        }
      }
      categories {
        nodes {
          name
          uri
          id
        }
      }
      seo {
        title
        metaDesc 
        readingTime
        canonical
        breadcrumbs {
          url
          text
        }
        schema {
          raw
        }
        opengraphImage {
          link
        }
      }
      date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      modified(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    relatedPosts: allWpNyheter {
      nodes {
        title
        uri
        id
        date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
        excerpt
        categories {
          nodes {
            name
            uri
            id
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    } 
  }
`
